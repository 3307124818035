import React from 'react';
import styles from './TermsFooter.module.scss';
import * as CSS from 'csstype';

interface TermsFooterProps {
  position?: CSS.Property.Position,
  className?: string,
}

function TermsFooter({
  position = 'absolute',
  className = '',
}: TermsFooterProps) {
  return (
    <div
      className={styles.terms_footer + ' ' + className}
      style={{ position: position }}
      role="contentinfo"
    >
      © 1999 - 2025{' '}
      <a href="https://www.w3schools.com" target="_blank" rel="noopener noreferrer">
        W3Schools
      </a>
      . All rights reserved.{' '}
      <a
        href="https://www.w3schools.com/about/about_privacy.asp"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cookie policy
      </a>
      ,{' '}
      <a
        href="https://www.w3schools.com/about/about_privacy.asp"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy
      </a>{' '}
      and{' '}
      <a
        href="https://www.w3schools.com/about/about_copyright.asp"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms
      </a>
      .
    </div>
  );
}

export default TermsFooter;
