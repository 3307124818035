import { EnvType, getEnv } from '../EnvironmentUtil';


export const getApiBaseDomain = (env?: EnvType): string => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  if (env === 'prod') {
    return 'w3sapi.com';
  } else if (env === 'staging') {
    return 'w3sapi-stage.com';
  } else if (env === 'develop') {
    return 'w3sapi-dev.com';
  }

  return 'w3sapi.com'; // prod fallback
};

export const getApiBaseUrl = (env?: EnvType): string => {
  return `https://api.${getApiBaseDomain(env)}`;
};

export const getKaiApiBaseUrl = (env?: EnvType): string => {
  return `https://api.kai.${getApiBaseDomain(env)}`;
};
