import {
  getQueryParamNamed,
} from './Util/QueryParamsUtil';
import { authUtil } from './Util/AuthUtil';

import { noop as _noop } from './Util/MixedUtil';
export const noop = _noop;

import {
  getEnv as _getEnv,
  getAppFromUrl as _getAppFromUrl,
  getAppName as _getAppName,
  getProfileUrl,
  getLoginUrl,
  getLogoutUrl,
  getMyLearningUrl,
  getBillingUrl,
  getUpgradeLink,
  getPublicProfileUrl,
  getProfileSettingsUrl,
  getSpacesUrl,
  getPathfinderUrl,
  getBuyTokensUrl,
  getClassicUrl
} from './Util/EnvironmentUtil';

export type { EnvType } from './Util/EnvironmentUtil';

import {
  getCookie as _getCookie,
  removeCookie as _removeCookie,
  setCookie as _setCookie
} from './Util/CookieUtil';

import { pack, unpack } from './Util/cookie/UicCryptUtil';
import { sendGoogleAnalyticsEvent } from './Util/GoogleAnalyticsUtil';
import {
  getUserInfoFromCookies,
  getSubscriptionPlan,
  changeStatusCodeInUserSessionCookies,
  processUserSession,
  logOutViaRedirect,
  logOut,
  refreshUserInfoToken,
} from './Util/UserSessionUtil';
import * as LoggingUtil from './Util/LoggingUtil';

import { setStrDataToLocalStorage as _setStrDataToLocalStorage } from './Util/DataUtil';

export const setStrDataToLocalStorage = _setStrDataToLocalStorage;

// authUtil.initAuth();

export const util = {
  getQueryParamNamed,
  getEnv: _getEnv, // DEPRECATED! Use the directly exposed one instead
  getAppFromUrl: _getAppFromUrl, // DEPRECATED! Use the directly exposed one instead
  getAppName: _getAppName, // DEPRECATED! Use the directly exposed one instead
  getProfileUrl,
  getLoginUrl,
  getLogoutUrl,
  getMyLearningUrl,
  getBillingUrl,
  getUpgradeLink,
  getPublicProfileUrl,
  getProfileSettingsUrl,
  getSpacesUrl,
  getPathfinderUrl,
  getClassicUrl,
  getBuyTokensUrl
};

export const getEnv = _getEnv;
export const getAppFromUrl = _getAppFromUrl;
export const getAppName = _getAppName;

export const cookieUtil = {
  getCookie: _getCookie, // DEPRECATED! Use the directly exposed one instead
  removeCookie: _removeCookie, // DEPRECATED! Use the directly exposed one instead
  setCookie: _setCookie, // DEPRECATED! Use the directly exposed one instead
  pack,
  unpack
};

export const getCookie = _getCookie;
export const removeCookie = _removeCookie;
export const setCookie = _setCookie;

export const auth = {
  processUserSession,
  changeStatusCodeInUserSessionCookies,
  /**
   * @deprecated Legacy user session refresh logic kept for backwards compatibility
   */
  // refreshUserSessionViaRedirect: authUtil.refreshUserSessionViaRedirect,
  refreshUserSession: authUtil.refreshUserSession,
  restartUserSessionViaRedirect: authUtil.restartUserSessionViaRedirect,
  userSessionRedirectionLogicHandler: authUtil.userSessionRedirectionLogicHandler,
  getUserInfoFromCookies,
  getSubscriptionPlan,
  logOutViaRedirect,
  logOut,
  refreshUserInfoToken,
};

export const GoogleAnalytics = {
  sendEvent: sendGoogleAnalyticsEvent
};

export type { CaptchaTokenType } from './Component/Captcha/Captcha';

export type { ExceptionHandlerPropsType } from './Util/ExceptionUtil';

export const logging = {
  logError: LoggingUtil.logError,
  logDebug: LoggingUtil.logDebug,
  logInfo: LoggingUtil.logInfo,
  logAll: LoggingUtil.logAll,
  log: LoggingUtil.log
};

// < fetch
export type { IFetchPrs, IFetchResult } from './Util/FetchUtil';

import {
  fetch as _fetch,
  setDefaultFetchConfig as _setDefaultFetchConfig
} from './Util/FetchUtil';
export const fetch = _fetch;
export const setDefaultFetchConfig = _setDefaultFetchConfig;

import { fetchProfile as _fetchProfile } from './Util/InternalProjects/ProfileUtil';
export const fetchProfile = _fetchProfile;

import { fetchPublicProfile as _fetchPublicProfile } from './Util/InternalProjects/PublicProfileUtil';
export const fetchPublicProfile = _fetchPublicProfile;

import { fetchMyLearning as _fetchMyLearning } from './Util/InternalProjects/MyLearningUtil';
export const fetchMyLearning = _fetchMyLearning;

import { fetchMyLearningLegacy as _fetchMyLearningLegacy } from './Util/InternalProjects/MyLearningLegacyUtil';
export const fetchMyLearningLegacy = _fetchMyLearningLegacy;

import {
  getApiBaseDomain as _getApiBaseDomain,
  getApiBaseUrl as _getApiBaseUrl,
  getKaiApiBaseUrl as _getKaiApiBaseUrl,
} from './Util/InternalProjects/BaseApiUtil';
export const getApiBaseDomain = _getApiBaseDomain;
export const getApiBaseUrl = _getApiBaseUrl;
export const getKaiApiBaseUrl = _getKaiApiBaseUrl;

import {
  fetchSpaces as _fetchSpaces,
  fetchSpacesDomains as _fetchSpacesDomains,
  fetchSpacesPluginLegacy as _fetchSpacesPlugin
} from './Util/InternalProjects/SpacesUtil';
export const fetchSpaces = _fetchSpaces;
export const fetchSpacesDomains = _fetchSpacesDomains;
export const fetchSpacesPlugin = _fetchSpacesPlugin;

import { fetchDynamicSpaces as _fetchDynamicSpaces } from './Util/InternalProjects/DynamicSpaceUtil';
export const fetchDynamicSpaces = _fetchDynamicSpaces;

import { fetchBilling as _fetchBilling } from './Util/InternalProjects/BillingUtil';
export const fetchBilling = _fetchBilling;

import { fetchCert as _fetchCert } from './Util/InternalProjects/CertUtil';
export const fetchCert = _fetchCert;

import { fetchPathfinder as _fetchPathfinder } from './Util/InternalProjects/PathfinderUtil';
export const fetchPathfinder = _fetchPathfinder;
// > fetch

// < components
import _TopNavBar from './Component/TopNavBar/TopNavBar';
export const TopNavBar = _TopNavBar;

import _SnigelAd from './Component/SnigelAd/SnigelAd';
export const SnigelAd = _SnigelAd;

import _TopBarMenu from './Component/TopBarMenu/TopBarMenu';
export const TopBarMenu = _TopBarMenu;

import _TopBarMenuLegacy2 from './Component/TopBarMenu/TopBarMenuLegacy2';
export const MenuBarV2 = _TopBarMenuLegacy2;

import _UserProfile from './Component/UserProfile/UserProfile';
export const UserProfileButton = _UserProfile;
export const UserComponent = _UserProfile;

import _LoginFullPage from './Component/LoginFullPage/LoginFullPage';
export const LoginPage = _LoginFullPage;

import _LogoutFullPage from './Component/LogoutFullPage/LogoutFullPage';
export const LogoutPage = _LogoutFullPage;

import _ResetPasswordRequest from './Component/ResetPasswordRequest/ResetPasswordRequest';
export const ResetPasswordReq = _ResetPasswordRequest;

import _ResetPasswordVerify from './Component/ResetPasswordVerify/ResetPasswordVerify';
export const ResetPasswordVer = _ResetPasswordVerify;

import _ClosePageButton from './Component/Buttons/ClosePageButton/ClosePageButton';
export const CloseMenuBarButton = _ClosePageButton;

import _LoadingModal from './Component/LoadingModal/LoadingModal';
export const LoadingModal = _LoadingModal; // DEPRECATED! Use LoadingBox instead

import _LoadingBox from './Component/LoadingBox/LoadingBox';
export const LoadingBox = _LoadingBox;

import _LoginModal from './Component/LoginModal/LoginModal';
export const ModalLogin = _LoginModal;

import _Captcha from './Component/Captcha/Captcha';
export const Captcha = _Captcha;

import _CloseIcon from './Component/Icons/CloseIcon/CloseIcon';
export const CloseModalIcon = _CloseIcon;

import _ProfileSettings from './Component/ProfileSettings/ProfileSettings';
export const ProfileSettings = _ProfileSettings;

import _Button from './Component/Buttons/Button/Button';
export const Button = _Button;

import _ButtonV2, {
  ButtonV2ItemPosition as _ButtonV2ItemPosition,
  ButtonV2RoundSize as _ButtonV2RoundSize,
  ButtonV2ShadowColor as _ButtonV2ShadowColor,
  ButtonV2Type as _ButtonV2Type
} from './Component/Buttons/ButtonV2/ButtonV2';
export const ButtonV2 = _ButtonV2;
export type ButtonV2ItemPosition = _ButtonV2ItemPosition
export type ButtonV2RoundSize = _ButtonV2RoundSize
export type ButtonV2ShadowColor = _ButtonV2ShadowColor
export type ButtonV2Type = _ButtonV2Type

// Do not uncomment Tooltip. Is not working correctly at the moment.

/* import _Tooltip from './Component/Tooltip/Tooltip';
export const Tooltip = _Tooltip; */

import _CloseButton from './Component/Buttons/CloseButton/CloseButton';
export const CloseButton = _CloseButton;

import _InfoBar from './Component/InfoBar/InfoBar';
export const InfoBar = _InfoBar;

import _TermsFooter from './Component/TermsFooter/TermsFooter';
export const Terms = _TermsFooter; // DEPRECATED! Use TermsFooter instead
export const TermsFooter = _TermsFooter;

import _Spinner from './Component/Spinner/Spinner';
export const Spinner = _Spinner;

import _Toggle from './Component/Buttons/ToggleButton/Toggle';
export const Toggle = _Toggle;

export type { IToggle } from './Component/Buttons/ToggleButton/Toggle';

// > components

// < icons

import _Stars from './Component/Icons/Stars/Stars';
export const StarsIcon = _Stars;

import _ExternalLinkV2 from './Component/Icons/ExternalLinkV2/ExternalLinkV2';
export const ExternalLinkV2 = _ExternalLinkV2;

// > icons

// version of the package, updated via "npm run bump-version"
export const getVersion = () => '1.5.42';
