import React from 'react';
import styles from './AcademyBanner.module.scss';

const AcademyBanner = () => {
  return (
    <div className={styles.academyBanner}>
        <b>Are you a teacher?</b> &nbsp; We just launched W3Schools Academy. &nbsp;
        <a
          href="https://w3schools.com/academy/index.php"
          target="new"
        >
          Check it out here
        </a>
      </div>
  );
};

export default AcademyBanner;
