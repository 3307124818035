import React, { useEffect, useRef, useState } from 'react';
import styles from './LoginPanel.module.scss';
import { getQueryParamNamed } from '../../../SharedLib/Util/QueryParamsUtil';
import { cacheRedirectUrl, getSanitizedRedirectUrl, tweakRedirectUrlOnSuccessfulSignup } from '../../../SharedLib/Util/RedirectUtil';
import { authUtil } from '../../../SharedLib/Util/AuthUtil';
import { CognitoHostedUIIdentityProvider } from '../../../SharedLib/Util/AmplifyAuth';
import SignUpForm from '../SignUpForm/SignUpForm';
import LoginForm from '../LoginForm/LoginForm';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import GitHubIcon from '../../Icon/GitHubIcon';
import SignupVerifyEmail from '../SignUpVerifyEmail/SignUpVerifyEmail';
import { useHistory, useLocation } from 'react-router-dom';
import W3LogoButton from '../../../SharedLib/Component/Buttons/W3LogoButton/W3LogoButton';
import { X } from 'react-bootstrap-icons';
import GoogleIconColored from '../../Icon/GoogleIconColored';
import FeideIconColored from '../../Icon/FeideIconColored';
import FacebookIconColored from '../../Icon/FacebookIconColored';
import { getClassicUrl } from '../../../SharedLib/Util/EnvironmentUtil';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';
import { Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import Overlay from 'react-bootstrap/esm/Overlay';
import { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import ConfirmWithNewPasswordForm from '../ConfirmWithNewPasswordForm/ConfirmWithNewPasswordForm';

export enum LoginView {
  LOGIN_SIGNUP = 'login_signup',
  LOGIN = 'login',
  SIGNUP = 'signup',
  RESET_PASSWORD = 'reset_password',
  VERIFICATION_PENDING = 'verification_pending',
  RESET = 'reset',
  CONFIRM_NEW_PASSWORD = 'confirm_new_password',
}

export interface ILoginPanelProps {
  className?: string;
}

const ToolTipWrapper = ({
  children,
  text,
  placement = 'right',
  delay = { show: 250, hide: 400 },
  target,
  container,
}: {
  children: JSX.Element;
  text: string;
  placement?: Placement;
  delay?: OverlayDelay;
  target: React.RefObject<HTMLElement>;
  container: React.RefObject<HTMLElement>;
}) => {
  const [show, setShow] = useState(false);

  const handleMouseOver = (event: MouseEvent) => {
    event.stopPropagation();
    setShow(true);
  };

  const handleMouseOut = (event: MouseEvent) => {
    event.stopPropagation();
    setShow(false);
  };

  useEffect(() => {
    if (target.current) {
      target.current.addEventListener('mouseover', handleMouseOver);
      target.current.addEventListener('mouseout', handleMouseOut);
    }

    return () => {
      if (target.current) {
        target.current.removeEventListener('mouseover', handleMouseOver);
        target.current.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [target.current]);

  return (
    <>
      <Overlay show={show} target={target} placement={placement} container={container}>
        <Popover id="popover-contained">
          <Popover.Body className={styles.popover_text}>{text}</Popover.Body>
        </Popover>
      </Overlay>
      {children}
    </>
  );
};

export const getViewFromPathname = (pathname: string) => {
  switch (pathname) {
    case '/reset-password': // anonymous reset password
      return LoginView.RESET_PASSWORD;
    case '/reset': // reset from email link. May be logged in
      return LoginView.RESET;
    case '/sign-up':
      return LoginView.SIGNUP;
    case '/signup':
      return LoginView.SIGNUP;
    case '/confirm-new_password':
      return LoginView.CONFIRM_NEW_PASSWORD;
    default:
      return LoginView.LOGIN;
  }
};

const LoginPanel: React.FC<ILoginPanelProps> = () => {
  const socialLoginProviders = ['Google', 'Facebook', 'Github', 'Feide'] as const;
  const location = useLocation();
  const [view, setView] = useState<LoginView>(getViewFromPathname(location.pathname));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [xButtonUrl, setXButtonUrl] = useState(getClassicUrl());
  const history = useHistory();
  const providerLogoMap: {
    [key in (typeof socialLoginProviders)[number]]: JSX.Element;
  } = {
    Google: <GoogleIconColored />,
    Facebook: <FacebookIconColored />,
    Github: <GitHubIcon />,
    Feide: <FeideIconColored />,
  };

  const targetRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const _xButtonUrl = getSanitizedRedirectUrl({
      skipOauthRedirectLookup: true,
      loggedOut: true,
      fallback: getClassicUrl(),
    });

    if (_xButtonUrl) {
      setXButtonUrl(_xButtonUrl);
    }
  }, []);

  useEffect(() => {
    if (view !== LoginView.VERIFICATION_PENDING) {
      history.push(`/${view.toLowerCase().replace('_', '-')}` + history.location.search);
    }
  }, [view, history]);

  useEffect(() => {
    setView(getViewFromPathname(location.pathname));
  }, [location.pathname]);

  async function federatedLogin(provider: CognitoHostedUIIdentityProvider | string) {
    // Store authorization_code, redirect_uri and state in session storage here. To be retrieved later in handleLoggedIn when directed back to profile after social login.
    sessionStorage.clear();
    const auth_code = getQueryParamNamed('authorization_code');
    const redirect_uri = getQueryParamNamed('redirect_uri');

    if (auth_code !== undefined) {
      sessionStorage.setItem('authorization_code', auth_code.value);

      const auth_state = getQueryParamNamed('state');

      if (auth_state !== undefined) {
        sessionStorage.setItem('state', auth_state.value);
      }
    }

    if (redirect_uri !== undefined) {
      sessionStorage.setItem('redirect_uri', redirect_uri.value);
    }

    cacheRedirectUrl();

    const federatedLoginRes = await authUtil.federatedLogin(provider);

    if (federatedLoginRes.status === 'loggedin') {
      tweakRedirectUrlOnSuccessfulSignup(); // TODO: (mid) find a way to distinguish between signup and login
    }
  }

  const tabChangeText = () => {
    if (view === LoginView.SIGNUP) {
      return (
        <div className={styles.header_login_text}>
          Already have an account?{' '}
          <span className={styles.login} onClick={() => setView(LoginView.LOGIN)}>
            Log in
          </span>
        </div>
      );
    }

    if (view === LoginView.LOGIN) {
      return (
        <div className={styles.header_login_text}>
          Don't have an account?{' '}
          <span className={styles.login} onClick={() => setView(LoginView.SIGNUP)}>
            Sign up
          </span>
        </div>
      );
    }
  };

  const headingText = () => {
    if (view === LoginView.SIGNUP) {
      return <h2>Sign Up</h2>;
    }

    if (view === LoginView.LOGIN) {
      return <h2>Log In</h2>;
    }
  };

  return (
    <div className={styles.login_panel}>
      <div
        className={styles.login_signup}
        style={{
          gridTemplateRows: view === LoginView.RESET_PASSWORD || view === LoginView.VERIFICATION_PENDING ? 'auto' : '60px 40px 120px auto',
        }}
      >
        <div className={styles.form_header}>
          <a href={xButtonUrl} className={styles.x_button}>
            <X size={'30'} />
          </a>
        </div>
        <div className={styles.form_header_mobile}>
          <W3LogoButton loggedIn={false} width="40" height="36" />
          <a href={xButtonUrl} className={styles.x_button}>
            <X size={'30'} />
          </a>
        </div>
        {(view === LoginView.LOGIN || view === LoginView.SIGNUP) && (
          <>
            {headingText()}
            {tabChangeText()}
          </>
        )}
        {(view === LoginView.LOGIN || view === LoginView.SIGNUP) && (
          <div className={styles.social_login_container}>
            <>
              <div className={styles.social_login_buttons} ref={containerRef}>
                {socialLoginProviders.map((provider, index) =>
                  provider === 'Feide' ? (
                    <ToolTipWrapper
                      key={index}
                      placement={'bottom'}
                      target={targetRef}
                      container={containerRef}
                      text={
                        'If you have any questions regarding access via Feide, please contact your administrator or email us at help@w3schools.com.'
                      }
                    >
                      <button key={index} className={styles.social_login_button} onClick={() => federatedLogin(provider)} ref={targetRef}>
                        <div className={styles.provider_text}>{provider}</div>
                        <div className={styles.provider_logo}>{providerLogoMap[provider]}</div>
                      </button>
                    </ToolTipWrapper>
                  ) : (
                    <button key={index} className={styles.social_login_button} onClick={() => federatedLogin(provider)}>
                      <div className={styles.provider_text}>{provider}</div>
                      <div className={styles.provider_logo}>{providerLogoMap[provider]}</div>
                    </button>
                  ),
                )}
              </div>
              <p className={styles.or_text}>OR</p>
            </>
          </div>
        )}
        <div className={styles.content}>
          {view === LoginView.SIGNUP && (
            <SignUpForm
              setView={setView}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              setUsername={setUsername}
            />
          )}

          {view === LoginView.LOGIN && (
            <LoginForm
              setView={setView}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              setUsername={setUsername}
            />
          )}

          {view === LoginView.RESET_PASSWORD && (
            <div className={styles.reset_password}>
              <ResetPasswordForm setView={setView} />
            </div>
          )}

          {view === LoginView.RESET && (
            <div className={styles.reset}>
              <ChangePasswordForm setView={setView} />
            </div>
          )}

          {view === LoginView.VERIFICATION_PENDING && (
            <div className={styles.verification_pending}>
              <SignupVerifyEmail email={email} username={username} setView={setView} />
            </div>
          )}

          {view === LoginView.CONFIRM_NEW_PASSWORD && (
            <div className={styles.confirm_new_password}>
              <ConfirmWithNewPasswordForm setView={setView} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPanel;
