import { CustomTypeMod } from '../InterfaceAndTypeUtil';
import { fetch, IFetchPrs, IFetchResult } from '../FetchUtil';
import { EnvType, getEnv } from '../EnvironmentUtil';
import { getApiBaseUrl } from './BaseApiUtil';


export const getSpacesApiBaseUrl = (env?: EnvType): string => {
  return `${getApiBaseUrl(env)}/spaces`;
};

/**
 * @deprecated
 */
export const getSpacesApiBaseUrlLegacy = (env?: EnvType): string => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  if (env === 'prod') {
    return 'https://api.w3spaces.com';
  } else if (env === 'staging') {
    return 'https://api.w3stages.com';
  } else if (env === 'develop') {
    return 'https://api.w3sdevelop.com';
  }

  return 'https://api.w3spaces.com'; // prod fallback
};

export const fetchSpaces = async<T>(prs: IFetchPrs): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = getSpacesApiBaseUrl();

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'Authorization';
  }

  return await fetch(prs);
};

export const getSpacesDomainsApiBaseUrl = (env?: EnvType): string => {
  return `${getApiBaseUrl(env)}/domains`;
};

export const fetchSpacesDomains = async<T>(prs: IFetchPrs): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = getSpacesDomainsApiBaseUrl();

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'Authorization';
  }

  return await fetch(prs);
};

/**
 * @deprecated
 */
export const getSpacesPluginApiBaseUrlLegacy = (env?: EnvType): string => {
  if (typeof env === 'undefined') {
    env = getEnv();
  }

  if (env === 'prod') {
    return 'https://spaces-api.w3spaces.com/api/v1';
  } else if (env === 'staging') {
    return 'https://spaces-api.w3stages.com/api/v1';
  } else if (env === 'develop') {
    return 'https://spaces-api.w3sdevelop.com/api/v1';
  }

  return 'https://spaces-api.w3spaces.com/api/v1'; // prod fallback
};

/**
 * @deprecated
 */
export const fetchSpacesPluginLegacy = async<T>(prs: IFetchPrs): Promise<CustomTypeMod<IFetchResult, {
  data: T,
}>> => {
  prs.baseURL = getSpacesPluginApiBaseUrlLegacy();

  if (typeof prs.authHeaderKey === 'undefined') {
    prs.authHeaderKey = 'Authorization';
  }

  return await fetch(prs);
};
