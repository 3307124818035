import React from 'react';
import styles from './Footer.module.scss';
import {
  Facebook,
  Discord,
  Linkedin,
  Instagram,
  Youtube,
} from 'react-bootstrap-icons';
import logoImage from './logo.png'; // Path to the white logo image

const Footer: React.FC = () => {
  return (
    <>
      <div className={styles.footerContainer}>
        <div className={styles.footerContent}>
          <div className={styles.leftColumn}>
            <div className={styles.brandLogo}>
              <img src={logoImage} alt="W3Schools" />
            </div>
            <div className={styles.socialLinks}>
              <a href="https://www.youtube.com/@w3schools" aria-label="YouTube">
                <Youtube />
              </a>
              <a
                href="https://www.linkedin.com/company/w3schools.com/"
                aria-label="LinkedIn"
              >
                <Linkedin />
              </a>
              <a href="https://discord.gg/6Z7UaRbUQM" aria-label="Discord">
                <Discord />
              </a>
              <a
                href="https://www.facebook.com/w3schoolscom/"
                aria-label="Facebook"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/w3schools.com_official/"
                aria-label="Instagram"
              >
                <Instagram />
              </a>
            </div>
            <p className={styles.disclaimer}>
              W3Schools is optimized for learning and training. Examples might
              be simplified to improve reading and learning. Tutorials,
              references, and examples are constantly reviewed to avoid errors,
              but we cannot warrant full correctness of all content. While using
              W3Schools, you agree to have read and accepted our terms of use,
              cookie and privacy policy.
            </p>
            <div className={styles.copyRight}>
              <a
                href="//w3schools.com/about/about_copyright.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Copyright 1999-2025
              </a>
              &nbsp;by Refsnes Data. All Rights Reserved.{' '}
              <a
                href="//w3schools.com/w3css/default.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                W3Schools is Powered by W3.CSS
              </a>
            </div>
          </div>
          <div className={styles.rightColumn}>
            {/* Right column content goes here, if any */}
          </div>
        </div>
      </div>
      <div className={styles.footerImageMobile}>
        {/* <img src={footerImage} alt="Footer image" /> */}
      </div>
    </>
  );
};

export default Footer;
